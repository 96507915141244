<ng-container [ngSwitch]="type">
  <ax-custom-property-edit
    *ngSwitchCase="PropertyType.CUSTOM"
    [requestedPropertyId]="requestedPropertyId"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [required]="required"
    [showIcon]="showIcon"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-custom-property-edit>

  <ax-proposal-file-edit
    *ngSwitchCase="PropertyType.FILE"
    [proposalId]="proposalId"
    [requestedPropertyId]="requestedPropertyId"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [demo]="demo"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-proposal-file-edit>

  <ax-proposal-image-edit
    *ngSwitchCase="PropertyType.IMAGE"
    [proposalId]="proposalId"
    [requestedPropertyId]="requestedPropertyId"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [demo]="demo"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-proposal-image-edit>

  <ax-property-edit
    *ngSwitchDefault
    [form]="form"
    [name]="name"
    [hint]="hint"
    [autofocus]="autofocus"
    [autocomplete]="autocomplete"
    [autocompleteProperties]="autocompleteProperties"
    [required]="required"
    [showErrors]="true"
    [showIcon]="showIcon"
    [isProposalSubscribeForm]="true"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-property-edit>
</ng-container>
