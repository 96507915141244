import {Component} from '@angular/core';
import {ProposalPropertyDescriptionPipe} from '@aztrix/helpers';
import {CustomFieldInfoRepresentation} from '@aztrix/sdk';

import {AbstractCustomPropertyView} from './abstract-custom-property-view';

@Component({
  selector: 'ax-custom-property-view',
  templateUrl: 'custom-property-view.component.html',
})
export class CustomPropertyViewComponent extends AbstractCustomPropertyView {
  proposalPropertyDescriptionPipe = new ProposalPropertyDescriptionPipe();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProposalCustomType = CustomFieldInfoRepresentation.TypeEnum;

  get customFieldType() {
    return this.requestedPropertyType?.customFieldInfo?.type;
  }
}
