<div class="overview">
  @for (item of (language$ | async)?.items; track item.orderIndex) {
    @switch (item.type) {
      @case ('PROPERTY') {
        @if (
          (hasSteps$ | async) === false &&
          (getAgreementPropertyForItem(item)?.property | propertyHasValue)
        ) {
          <ax-agreement-property-view
            [proposal]="proposal"
            [agreementProperty]="getAgreementPropertyForItem(item)"
            [property]="getPropertyForItem(item)"
            [language]="language"
            [myProfile]="myProfile"
            [showOutOfSync]="showOutOfSync"
            [agreementActive]="agreementActive"
            [agreementConfirmed]="agreementConfirmed"
            (addProperty)="addProperty.next($event)"
            (editProperty)="editProperty.next($event)"
          ></ax-agreement-property-view>
        }
      }

      @case ('TEMPLATE') {
        <ax-agreement-template-view
          [proposal]="proposal"
          [language]="language"
          [item]="item"
          [agreementProperties]="agreementProperties"
        ></ax-agreement-template-view>
      }

      @default {
        <ax-agreement-step-view
          *ngIf="!stepSkipped(item)"
          [proposal]="proposal"
          [item]="item"
          [step]="getStepForItem(item)"
          [agreementProperties]="agreementProperties"
          [language]="(language$ | async) || undefined"
          [myProfile]="myProfile"
          [showOutOfSync]="showOutOfSync"
          [agreementActive]="agreementActive"
          [agreementConfirmed]="agreementConfirmed"
          (addProperty)="addProperty.next($event)"
          (editProperty)="editProperty.next($event)"
        ></ax-agreement-step-view>
      }
    }
  }
</div>

<ax-agreement-documents
  class="overview"
  *ngIf="agreementDocuments?.length || language?.documents?.length"
  [isInAgreement]="isInAgreement"
  [agreementDocuments]="agreementDocuments"
  [language]="language || undefined"
  [accepted]="agreementConfirmed"
></ax-agreement-documents>
