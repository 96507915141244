import {Component, Input} from '@angular/core';
import {agreementDocumentByProposalDocumentId} from '@aztrix/helpers';
import {AgreementDocumentRepresentation, ProposalLanguageRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-agreement-documents',
  templateUrl: './agreement-documents.component.html',
  styleUrls: ['./agreement-documents.component.scss', '../../property.scss'],
})
export class AgreementDocumentsComponent {
  @Input() language?: ProposalLanguageRepresentation;
  @Input() accepted: boolean;
  @Input() isInAgreement = true;

  @Input() agreementDocuments: AgreementDocumentRepresentation[];

  agreementDocumentByProposalDocumentId = agreementDocumentByProposalDocumentId;
}
