<ng-content></ng-content>
<div class="title" [ngClass]="{open: open}" (click)="open = !open">
  <ax-icon class="icon" [name]="open ? 'chevron-down' : 'chevron-right'"></ax-icon>
  <span>{{ label }}</span>
</div>
<ax-proposal-subscribe-property
  [ngClass]="{hidden: !open}"
  *ngFor="let proposalProperty of proposalProperties"
  [form]="getPropertyForm(proposalProperty?.requestedPropertyId)"
  [proposalId]="proposal.id"
  [proposalProperty]="proposalProperty"
  [autocompleteProperties]="
    proposalProperty | autocompleteProperties: agreement : myProfile : autocompleteProperties
  "
  [language]="language"
  [myProfile]="myProfile"
  [required]="isOwner ? false : isRequired(proposalProperty.requestedPropertyId)"
  [isOwner]="isOwner"
  [demo]="demo"
  [confirmed]="agreement?.agreementData?.confirmed || false"
></ax-proposal-subscribe-property>
