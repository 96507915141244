import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';
import {CustomFieldInfoRepresentation} from '@aztrix/sdk';
import {Subscription} from 'rxjs';

import {AbstractProposalPropertyEdit} from './abstract-proposal-property-edit';

@Component({
  selector: 'ax-proposal-property-edit',
  templateUrl: './proposal-property-edit.component.html',
  styleUrls: ['./proposal-property-edit.component.scss'],
})
export class ProposalPropertyEditComponent
  extends AbstractProposalPropertyEdit
  implements OnInit, OnDestroy
{
  @Input() autocomplete = false;
  @Input() autocompleteProperties: Property[];
  @Input() demo = false;
  @Input() override showIcon = true;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  subscription = new Subscription();

  ngOnInit() {
    // fix for control updating value when editing in other control
    // https://github.com/angular/angular/issues/13792#issuecomment-283945502
    this.subscription = this.form.valueChanges.subscribe(
      (value) =>
        this.valueControl?.setValue(value.value, {
          emitEvent: false,
          emitModelToViewChange:
            this.customFieldType !== CustomFieldInfoRepresentation.TypeEnum.RICH_TEXT,
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get propertyType(): PropertyType | undefined {
    return this.form?.value?.type;
  }
}
