import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {getPropertyDescription, hasValue, HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {Profile, Property, PropertyType} from '@aztrix/models';
import {
  ProfilePropertyRepresentation,
  ProfileRepresentation,
  ProposalLanguageRepresentation,
  RequestedPropertyRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';
import {ProposalPropertyEditModule} from '@aztrix/subscribe/proposal-property-edit';
import {TranslatePipe} from '@aztrix/translate';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {Subscription} from 'rxjs';

import {PropertiesOfTypePipe} from './properties-of-type.pipe';

@Component({
  selector: 'ax-proposal-subscribe-property',
  templateUrl: 'proposal-subscribe-property.component.html',
  styleUrls: ['proposal-subscribe-property.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HelperPipesModule,
    MatFormFieldModule,
    MatSelectModule,
    IconsModule,
    ProposalPropertyEditModule,
    PropertiesOfTypePipe,
    TranslatePipe,
  ],
})
export class ProposalSubscribePropertyComponent implements OnDestroy {
  @Input() form: UntypedFormGroup | undefined;
  @Input() proposalId?: string;
  @Input() proposalProperty?: RequestedPropertyRepresentation;
  @Input() autocompleteProperties: Property[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: Profile | ProfileRepresentation;
  @Input() required = false;
  @Input() confirmed = false;
  @Input() isOwner = false;
  @Input() demo = false;
  @Input() showIcon = true;
  @Input() autofocus = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  private _subscription = new Subscription();

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  didAddProperty(property?: Property) {
    this.propertyControl?.setValue(hasValue(property) ? property : {});
  }

  getRequestedPropertyType(
    property: RequestedPropertyRepresentation | undefined,
    type: PropertyType | undefined
  ): RequestedPropertyTypeRepresentation | undefined {
    return property?.requestedPropertyTypes?.find((rpt) => rpt.type === type);
  }

  propertyAlignTop(proposalProperty: RequestedPropertyRepresentation): boolean {
    const propertyType = proposalProperty.requestedPropertyTypes?.[0].type;
    const customFieldType = proposalProperty.requestedPropertyTypes?.[0].customFieldInfo?.type;
    if (propertyType === 'OPENING_HOURS') {
      return true;
    }
    if (propertyType === 'GENDER') {
      return true;
    }
    if (customFieldType === 'CHECK' || customFieldType === 'RADIO') {
      return true;
    }
    return false;
  }

  get requestedPropertyTypes() {
    return [...(this.proposalProperty?.requestedPropertyTypes || [])]?.sort((p1, p2) => {
      return (p1?.orderIndex || Infinity) < (p2?.orderIndex || Infinity) ? -1 : 1;
    });
  }

  get propertyType(): PropertyType | null {
    const control = this.propertyTypeControl;
    return control ? control.value : null;
  }

  get propertyControls(): UntypedFormGroup | undefined {
    return this.form ? <UntypedFormGroup>this.form.get('properties') : undefined;
  }

  get propertyControlsList(): FormGroup<ControlsOf<ProfilePropertyRepresentation>>[] {
    return <FormGroup<ControlsOf<ProfilePropertyRepresentation>>[]>(
      Object.values(this.propertyControls?.controls || [])
    );
  }

  get propertyControl(): UntypedFormControl | undefined {
    if (!this.propertyType) {
      return undefined;
    }
    return <UntypedFormControl>this.propertyControls?.get(this.propertyType);
  }

  get propertyTypeControl(): UntypedFormControl | undefined {
    return this.form ? <UntypedFormControl>this.form.get('propertyType') : undefined;
  }

  get canDisplay(): boolean {
    if (this.isOwner) {
      return true;
    }
    if (this.isReadonly) {
      return this.propertyControlsList.some((control) => hasValue(control.value));
    }
    if (!this.isReadonly) {
      return true;
    }
    return false;
  }

  get isReadonly(): boolean {
    const description = getPropertyDescription(
      this.language,
      this.proposalProperty?.requestedPropertyId
    );
    return this.proposalProperty && !this.required ? !!description?.readonly : false;
  }
}
