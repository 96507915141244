import {Component} from '@angular/core';
import {CustomFieldInfoRepresentation} from '@aztrix/sdk';

import {AbstractProposalPropertyEdit} from '../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-property-edit',
  templateUrl: 'custom-property-edit.component.html',
  styleUrls: ['custom-property-edit.component.scss'],
})
export class CustomPropertyEditComponent extends AbstractProposalPropertyEdit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProposalCustomType = CustomFieldInfoRepresentation.TypeEnum;
}
