import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-subscribe-overview',
  templateUrl: 'proposal-subscribe-overview.component.html',
  styleUrls: ['proposal-subscribe-overview.component.scss'],
})
export class ProposalSubscribeOverviewComponent {
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() proposal?: ProposalRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() expired = false;
  @Input() showOutOfSync = true;
  @Input() agreementActive = true;
  @Input() agreementConfirmed = false;
  @Input() isInAgreement = true;
  @Input() agreementDocuments: AgreementDocumentRepresentation[];
  @Output() addProperty = new EventEmitter<PropertyRepresentation>();
}
