<ax-value-edit
  type="URL"
  [form]="valueControl"
  [name]="name"
  [autofocus]="autofocus"
  [label]="label"
  [hint]="hint"
  [required]="required"
  [icon]="showIcon ? customIcon ?? 'link' : undefined"
  [errorsTemplate]="errors"
  [readonly]="readonly"
  [enableValidation]="enableValidation"
></ax-value-edit>

<ng-template #errors>
  <ax-property-error [form]="form" [label]="label"></ax-property-error>
</ng-template>
