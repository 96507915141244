import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {isRequiredProperty} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {
  AgreementRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';

import {ProposalSubscribePropertyComponent} from '../../proposal-subscribe-property/proposal-subscribe-property.component';
import {AutocompletePropertiesPipe} from '../autocomplete-properties.pipe';

@Component({
  selector: 'ax-proposal-subscribe-properties-group',
  templateUrl: './proposal-subscribe-properties-group.component.html',
  styleUrls: ['./proposal-subscribe-properties-group.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    AutocompletePropertiesPipe,
    ProposalSubscribePropertyComponent,
  ],
})
export class ProposalSubscribePropertiesGroupComponent {
  @Input() label = '';
  @Input() form: UntypedFormGroup;
  @Input() proposal: ProposalRepresentation;
  @Input() proposalProperties: RequestedPropertyRepresentation[];
  @Input() autocompleteProperties: PropertyRepresentation[];
  @Input() language: ProposalLanguageRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() external = false;
  @Input() isOwner = false;
  @Input() demo = false;

  open = false;

  getPropertyForm(requestedPropertyId: string | undefined): UntypedFormGroup {
    if (!requestedPropertyId || !this.form) {
      return new UntypedFormGroup({});
    }

    return <UntypedFormGroup>this.form.get(requestedPropertyId);
  }

  isRequired(requestedPropertyId: string | undefined): boolean {
    return isRequiredProperty(this.language, requestedPropertyId);
  }
}
