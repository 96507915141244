<div class="step-title" *ngIf="step?.title">
  <h3>{{ step?.title }}</h3>
  <p>{{ step?.description }}</p>
</div>

@for (childItem of item.items; track item.orderIndex) {
  @switch (childItem.type) {
    @case ('TEMPLATE') {
      <ax-agreement-template-view
        [proposal]="proposal"
        [language]="language"
        [item]="childItem"
        [agreementProperties]="agreementProperties"
      ></ax-agreement-template-view>
    }

    @default {
      @if (getAgreementPropertyForItem(childItem)?.property | propertyHasValue) {
        <ax-agreement-property-view
          [proposal]="proposal"
          [agreementProperty]="getAgreementPropertyForItem(childItem)"
          [property]="getPropertyForItem(childItem)"
          [language]="language"
          [myProfile]="myProfile"
          [showOutOfSync]="showOutOfSync"
          [agreementActive]="agreementActive"
          [agreementConfirmed]="agreementConfirmed"
          (addProperty)="addProperty.next($event)"
          (editProperty)="editProperty.next($event)"
        ></ax-agreement-property-view>
      }
    }
  }
}
