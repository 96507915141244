import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  canAddPropertyType,
  getPropertyTuples,
  hasEqualValue,
  isOwner,
  propertyOutOfSync,
  typeFilter,
} from '@aztrix/helpers';
import {Property, PropertyType} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'ax-agreement-property-view',
  templateUrl: './agreement-property-view.component.html',
  styleUrls: ['./agreement-property-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementPropertyViewComponent {
  @Input() proposal?: ProposalRepresentation;
  @Input() agreementProperty?: AgreementPropertyRepresentation;
  @Input() property?: RequestedPropertyRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() showOutOfSync = true;
  @Input() agreementActive = true;
  @Input() agreementConfirmed = false;

  @Output() addProperty = new EventEmitter<PropertyRepresentation>();
  @Output() editProperty = new EventEmitter<PropertyRepresentation | undefined>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  isOwnerCheck() {
    return isOwner(this.proposal, this.myProfile);
  }

  isOutOfSyncProperty(property: Property | undefined): boolean {
    return (
      property?.type !== PropertyType.CUSTOM &&
      propertyOutOfSync(property, this.myProfile) &&
      !this.isOwnerCheck()
    );
  }

  canAddProperty(property: Property | PropertyRepresentation | undefined): boolean {
    if (!this.myProfile || !this.showOutOfSync || !property) {
      return false;
    }
    if (!canAddPropertyType((<PropertyRepresentation>property).type, this.myProfile)) {
      return false;
    }
    return !getPropertyTuples(this.myProfile.propertyContexts, typeFilter(property.type))
      .map((tuple) => tuple.property)
      .filter((profileProperty) => hasEqualValue(property, profileProperty)).length;
  }

  get isAgreementEdit() {
    return window.location.href.endsWith('/edit');
  }

  get isExternalAgreement() {
    return window.location.href.includes('agreement/external');
  }

  get isExternalAgreementView() {
    return window.location.href.includes('agreement/external');
  }

  get isExternalAgreementConfirm() {
    return window.location.href.includes('agreement/confirm');
  }
}
