import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {getPropertyTuples, hasEqualValue, hasValue, Subscribe, typeFilter} from '@aztrix/helpers';
import {AgreementVerificationType, Property, PropertyType} from '@aztrix/models';
import {ProfileRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ax-proposal-subscribe-verification-form',
  templateUrl: './proposal-subscribe-verification-form.component.html',
  styleUrls: ['./proposal-subscribe-verification-form.component.scss'],
})
export class ProposalSubscribeVerificationFormComponent implements OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() verified = false;

  private _subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.form) {
      this._subscription?.unsubscribe();
      this._subscription = this.form.valueChanges.subscribe((value) => {
        const emailProperty = this._getFirstAgreementPropertyOfType(value, PropertyType.EMAIL);
        const mobileProperty = this._getFirstAgreementPropertyOfType(
          value,
          PropertyType.MOBILE_PHONE
        );

        const verificationControl = this.formVerificationPropertyControl;
        const verificationProperty = verificationControl?.value;

        if (verificationControl?.pristine) {
          if (
            verificationProperty.type === PropertyType.EMAIL &&
            emailProperty?.value &&
            !hasEqualValue(verificationProperty, emailProperty)
          ) {
            verificationControl.setValue(emailProperty);
          }
          if (
            verificationProperty.type === PropertyType.MOBILE_PHONE &&
            mobileProperty?.value &&
            !hasEqualValue(verificationProperty, mobileProperty)
          ) {
            verificationControl.setValue(mobileProperty);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  get possibleProperties(): Property[] {
    if (
      this.proposal?.verification === AgreementVerificationType.EMAIL ||
      this.proposal?.verification === AgreementVerificationType.MOBILE
    ) {
      let properties = <Property[]>(
        Subscribe.formProperties(this.form.value).filter(
          (property) => property.type === this.verificationType && hasValue(property)
        )
      );

      if (this.myProfile) {
        const profileProperties = <Property[]>getPropertyTuples(
          this.myProfile.propertyContexts,
          typeFilter(this.verificationType)
        )
          .map((tuple) => (tuple ? tuple.property : null))
          .filter((property) => !!property);
        properties = [...profileProperties, ...properties];
      }

      return properties;
    }

    return [];
  }

  get formVerification() {
    return <UntypedFormGroup>this.form?.get('verification');
  }

  get formVerificationPropertyControl() {
    return this.formVerification?.get('property');
  }

  get verificationType() {
    return this.formVerificationPropertyControl?.value?.type;
  }

  private _getFirstAgreementPropertyOfType(value: any, type: PropertyType) {
    return Subscribe.formAgreementProperties(value)
      .filter((ap) => hasValue(ap.property))
      .filter((prop) => prop.property?.type === type)[0]?.property;
  }

  get isOwner() {
    return this.myProfile?.id === this.proposal?.ownerId;
  }
}
