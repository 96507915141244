import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {getLatestOrDefaultLanguage, getPendingLanguage} from '@aztrix/helpers';
import {AgreementVerificationType} from '@aztrix/models';
import {
  AgreementRepresentation,
  ExternalAgreementRepresentation,
  MetadataRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, shareReplay, startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-proposal-subscribe-form',
  templateUrl: 'proposal-subscribe-form.component.html',
  styleUrls: ['proposal-subscribe-form.component.scss'],
})
export class ProposalSubscribeFormComponent implements OnChanges {
  @Input() form?: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() agreementCode?: string;
  @Input() myProfile?: ProfileRepresentation;
  @Input() languageCode?: string;
  @Input() collapsibleGroups = [];
  @Input() autocompleteProperties?: PropertyRepresentation[] = [];
  @Input() subscriberMetadata: MetadataRepresentation[];
  @Input() orientation: 'VERTICAL' | 'HORIZONTAL' = 'VERTICAL';
  @Input() edit = false;
  @Input() demo = false;
  @Input() open = true;
  @Input() external = true;
  @Input() noSubscribeButton = false;
  @Input() subscribeLabel = '';
  @Output() subscribed = new EventEmitter<ExternalAgreementRepresentation>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProposalType = PropertyRepresentation.TypeEnum;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AgreementVerificationType = AgreementVerificationType;

  allPropertiesForm: UntypedFormGroup;

  @MediaQuery('(min-width: 37.5rem)')
  @HostBinding('class.medium')
  medium = false;

  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);
  private _languageCode$ = new BehaviorSubject<string | undefined>(undefined);

  private _activeLanguageCode$ = combineLatest([this._languageCode$, this._translate.lang$]).pipe(
    map(([languageCode, activeLang]) => languageCode || activeLang)
  );

  requestedProperties$: Observable<RequestedPropertyRepresentation[]> = this._proposal$.pipe(
    map((proposal) => proposal?.requestedProperties || []),
    startWith([])
  );

  language$: Observable<ProposalLanguageRepresentation | undefined> = combineLatest([
    this._proposal$,
    this._activeLanguageCode$,
  ]).pipe(
    map(([proposal, languageCode]) =>
      this.edit
        ? <ProposalLanguageRepresentation>getPendingLanguage(proposal, languageCode)
        : <ProposalLanguageRepresentation>getLatestOrDefaultLanguage(proposal, languageCode)
    ),
    shareReplay(1)
  );

  whiteLabel$ = this.language$.pipe(map((l) => !!l?.whiteLabel));

  constructor(
    private _translate: TranslateService,
    resize: ObserveResizeService,
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef
  ) {
    resize.register(this, elementRef, changeDetector);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.proposal && changes.proposal) {
      this._proposal$.next(this.proposal);
    }
    if (this.languageCode && changes.languageCode) {
      this._languageCode$.next(this.languageCode);
    }
  }
}
