import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {getStepRequestedPropertyIds, sortAgreementProperties} from '@aztrix/helpers';
import {
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageRepresentation,
  ProposalLanguageStepRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-step-view',
  templateUrl: './agreement-step-view.component.html',
  styleUrls: ['./agreement-step-view.component.scss'],
})
export class AgreementStepViewComponent implements OnChanges {
  @Input() proposal?: ProposalRepresentation;
  @Input() item: ProposalLanguageItemWithStepRepresentation;
  @Input() step?: ProposalLanguageStepRepresentation;
  @Input() agreementProperties?: AgreementPropertyRepresentation[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() showOutOfSync = true;
  @Input() agreementActive = true;
  @Input() agreementConfirmed = false;
  @Output() addProperty = new EventEmitter<PropertyRepresentation>();
  @Output() editProperty = new EventEmitter<PropertyRepresentation | undefined>();

  private _language$ = new ReplaySubject<ProposalLanguageRepresentation>(1);
  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);

  properties$ = new ReplaySubject<AgreementPropertyRepresentation[]>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyRepresentation.TypeEnum;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }

    if (changes.language && this.language) {
      this._language$.next(this.language);
    }

    if (changes.agreementProperties && this.agreementProperties) {
      if (this.step) {
        let properties = this.agreementProperties.filter((p) =>
          getStepRequestedPropertyIds(this.step, this.language?.items).includes(
            p.requestedPropertyId || ''
          )
        );
        if (this.proposal) {
          properties = sortAgreementProperties(this.proposal, this.language, properties);
          this.properties$.next(properties);
        }
      } else {
        this.properties$.next(this.agreementProperties);
      }
    }
  }

  getAgreementPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return this.agreementProperties?.find(
      (ap) => ap.requestedPropertyId === item?.requestedPropertyId
    );
  }

  getPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return this.proposal?.requestedProperties?.find(
      (p) => p.requestedPropertyId === item?.requestedPropertyId
    );
  }
}
