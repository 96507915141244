import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {isRequiredProperty} from '@aztrix/helpers';
import {
  AgreementRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageItemRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageItemWithTextTemplateRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

import {ProposalSubscribePropertyComponent} from '../proposal-subscribe-property/proposal-subscribe-property.component';
import {ProposalSubscribeTextTemplateComponent} from '../proposal-subscribe-text-template/proposal-subscribe-text-template.component';
import {AutocompletePropertiesPipe} from './autocomplete-properties.pipe';
import {ProposalSubscribePropertiesGroupComponent} from './proposal-subscribe-properties-group/proposal-subscribe-properties-group.component';

@Component({
  selector: 'ax-proposal-subscribe-items',
  styleUrls: ['proposal-subscribe-items.component.scss'],
  templateUrl: 'proposal-subscribe-items.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AutocompletePropertiesPipe,
    ProposalSubscribePropertiesGroupComponent,
    ProposalSubscribePropertyComponent,
    ProposalSubscribeTextTemplateComponent,
  ],
})
export class ProposalSubscribeItemsComponent {
  @Input() form: UntypedFormGroup | AbstractControl;
  @Input() itemForm: UntypedFormGroup | AbstractControl;
  @Input() proposal?: ProposalRepresentation;
  @Input() autocompleteProperties: PropertyRepresentation[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() item?: ProposalLanguageItemWithStepRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() isOwner = false;
  @Input() demo = false;

  getProposalProperty(item: ProposalLanguageItemWithPropertyRepresentation) {
    return this.proposal?.requestedProperties?.find(
      (p) => p.requestedPropertyId === item?.requestedPropertyId
    );
  }

  getTextTemplateForm(textTemplateId: string | undefined) {
    if (!textTemplateId) {
      return undefined;
    }
    return <UntypedFormGroup>(
      (<UntypedFormArray>this.form?.get('textTemplates')).controls.find(
        (c) => c.value.id === textTemplateId
      )
    );
  }

  get sortedItems() {
    return [...(this.item?.items || [])].sort((i1, i2) =>
      (i1.orderIndex ?? Infinity) > (i2.orderIndex ?? Infinity) ? 1 : -1
    );
  }

  propertyItem(item: ProposalLanguageItemRepresentation) {
    return <ProposalLanguageItemWithPropertyRepresentation>item;
  }

  textTemplateItem(item: ProposalLanguageItemRepresentation) {
    return <ProposalLanguageItemWithTextTemplateRepresentation>item;
  }

  isRequired(item: ProposalLanguageItemWithPropertyRepresentation) {
    return isRequiredProperty(this.language, this.getProposalProperty(item)?.requestedPropertyId);
  }
}
