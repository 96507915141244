import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {sortSteps} from '@aztrix/helpers';
import {AgreementDocument} from '@aztrix/models';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageItemWithTextTemplateRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {combineLatest, map, ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-data',
  templateUrl: './agreement-data.component.html',
  styleUrls: ['./agreement-data.component.scss', '../overview.scss'],
})
export class AgreementDataComponent implements OnChanges {
  @Input() myProfile?: ProfileRepresentation;
  @Input() proposal?: ProposalRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreementProperties?: AgreementPropertyRepresentation[];
  @Input() agreementDocuments: (AgreementDocument | AgreementDocumentRepresentation)[];

  @Input() showOutOfSync = true;
  @Input() agreementConfirmed = false;
  @Input() agreementActive = true;

  @Input() isInAgreement = true;

  @Output() addProperty = new EventEmitter<PropertyRepresentation>();
  @Output() editProperty = new EventEmitter<PropertyRepresentation | undefined>();

  private _language$ = new ReplaySubject<ProposalLanguageRepresentation>(1);
  private _proposal$ = new ReplaySubject<ProposalRepresentation | undefined>(1);

  language$ = combineLatest([this._language$]).pipe(
    map(([language]) => {
      return {
        ...language,
        steps: sortSteps(language.items, language.steps),
      };
    }),
    map((language) => {
      return <ProposalLanguageRepresentation>{
        ...language,
        items: [...(language.items || [])]?.sort((i1, i2) =>
          (i1.orderIndex || 0) > (i2.orderIndex || 0) ? 1 : -1
        ),
      };
    })
  );

  hasSteps$ = this.language$.pipe(map((language) => !!language.steps?.length));

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }
  }

  getAgreementPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return this.agreementProperties?.find(
      (ap) => ap.requestedPropertyId === item?.requestedPropertyId
    );
  }

  getPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return this.proposal?.requestedProperties?.find(
      (p) => p.requestedPropertyId === item?.requestedPropertyId
    );
  }

  stepSkipped(item: ProposalLanguageItemWithStepRepresentation): boolean {
    let skipped = true;
    for (const childItem of item.items || []) {
      switch (childItem.type) {
        case 'TEMPLATE': {
          for (const childChildItem of (<ProposalLanguageItemWithTextTemplateRepresentation>(
            childItem
          )).items || []) {
            if (childChildItem.type === 'PROPERTY') {
              const agreementProperty = this.agreementProperties?.find(
                (p) => p.requestedPropertyId === childChildItem.requestedPropertyId
              );
              if (agreementProperty) {
                skipped = false;
                break;
              }
            }
          }
          break;
        }
        case 'PROPERTY': {
          const agreementProperty = this.agreementProperties?.find(
            (p) =>
              p.requestedPropertyId ===
              (<ProposalLanguageItemWithPropertyRepresentation>childItem).requestedPropertyId
          );
          if (agreementProperty) {
            skipped = false;
            break;
          }
          break;
        }
        default:
          continue;
      }
    }

    return skipped;
  }

  getStepForItem(item: ProposalLanguageItemWithStepRepresentation) {
    if (!item.stepId) {
      return undefined;
    }
    return this.language?.steps?.find((step) => step.id === item.stepId);
  }
}
