import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {getStepRequestedPropertyIds, isRequiredProperty} from '@aztrix/helpers';
import {
  AgreementRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';
import {combineLatest, Observable, ReplaySubject, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

export interface PropertyWithSkip {
  requestedPropertyId: string;
  type: 'CUSTOM';
  value: string;
}

@Component({
  selector: 'ax-proposal-subscribe-step',
  templateUrl: './proposal-subscribe-step.component.html',
  styleUrls: [
    './proposal-subscribe-step.component.scss',
    '../../proposal-subscribe-form.component.scss',
  ],
})
export class ProposalSubscribeStepComponent implements OnChanges {
  @Input() form: UntypedFormGroup | AbstractControl;
  @Input() stepForm: UntypedFormGroup | AbstractControl;
  @Input() proposal?: ProposalRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() item: ProposalLanguageItemWithStepRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() demo = false;
  @Input() collapsibleGroups = [];
  @Input() autocompleteProperties: PropertyRepresentation[] = [];

  // TODO: figure out solution for verification
  @Input() last = false;

  private _form$ = new ReplaySubject<UntypedFormGroup>(1);
  private _stepForm$ = new ReplaySubject<UntypedFormGroup>(1);
  private _orderIndex$ = new ReplaySubject<number>(1);
  private _language$ = new ReplaySubject<ProposalLanguageRepresentation>(1);
  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);

  subscriptions = new Subscription();

  private _requestedPropertyIds$ = combineLatest([this._language$, this._stepForm$]).pipe(
    map(([language, stepForm]) => {
      return getStepRequestedPropertyIds(stepForm.value, language.items);
    })
  );

  properties$: Observable<(RequestedPropertyRepresentation | undefined)[]> = combineLatest([
    this._requestedPropertyIds$,
    this._proposal$,
  ]).pipe(
    map(([requestedPropertyIds, proposal]) => {
      return (requestedPropertyIds || []).map(
        (id) => proposal.requestedProperties?.find((p) => p.requestedPropertyId === id)
      );
    }),
    shareReplay(1)
  );

  requiredProperties$ = combineLatest([this.properties$, this._language$]).pipe(
    map(([properties, language]) => {
      return properties.filter((p) => isRequiredProperty(language, p?.requestedPropertyId));
    })
  );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }

    if (changes.form && this.form) {
      this._form$.next(<UntypedFormGroup>this.form);
    }

    if (changes.stepForm && this.stepForm) {
      this._stepForm$.next(<UntypedFormGroup>this.stepForm);
      this._orderIndex$.next(this.stepForm.get('orderIndex')?.value);
    }

    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
  }

  get isOwner() {
    return this.proposal?.ownerId === this.myProfile?.id;
  }

  get typedStepForm() {
    return <FormGroup>this.stepForm;
  }

  // get stepHasSomethingToDisplay(): boolean {
  //   if (this.stepForm) {
  //     const textTemplateValue = this.stepForm.get('textTemplate')?.value;
  //     const hasPropertiesToDisplay = Object.entries(this.stepForm.get('properties')?.value).length;
  //     return !!textTemplateValue || !!hasPropertiesToDisplay;
  //   } else {
  //     return false;
  //   }
  // }
}
