import {Directive, Input} from '@angular/core';
import {Property} from '@aztrix/models';
import {
  CustomFieldValueLabelRepresentation,
  RequestedPropertyTypeDescriptionRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';

@Directive()
export abstract class AbstractCustomPropertyView {
  @Input() property: Property;
  @Input() requestedPropertyType?: RequestedPropertyTypeRepresentation;
  @Input() requestedPropertyTypeDescription?: RequestedPropertyTypeDescriptionRepresentation;
  @Input() isInListView = false;

  get valueLabels(): CustomFieldValueLabelRepresentation[] {
    return this.requestedPropertyTypeDescription?.customFieldDescription?.valueLabels || [];
  }
}
