import {Component, Input} from '@angular/core';
import {
  AgreementRepresentation,
  AgreementsService,
  ProposalLanguageRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-download-pdf-button',
  templateUrl: './agreement-download-pdf-button.component.html',
  styleUrls: ['./agreement-download-pdf-button.component.scss'],
})
export class AgreementDownloadPdfButtonComponent {
  @Input() agreement?: AgreementRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() color: 'primary' | 'transparent' = 'primary';

  loading$ = new BehaviorSubject(false);

  constructor(private _agreements: AgreementsService) {}

  downloadPdf() {
    this.loading$.next(true);
    return this._agreements
      .getAgreementOverviewPdfWithAgreementCode(
        this.agreement?.agreementCode || '',
        this.language?.languageCode || ''
      )
      .subscribe((pdf) => {
        const blob = new Blob([pdf], {type: 'application/pdf'});
        window.open(window.URL.createObjectURL(blob));
        this.loading$.next(false);
      });
  }
}
