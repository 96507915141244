<ng-container [ngSwitch]="customFieldType">
  <ax-custom-check-edit
    *ngSwitchCase="ProposalCustomType.CHECK"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-check-edit>
  <ax-custom-radio-edit
    *ngSwitchCase="ProposalCustomType.RADIO"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-radio-edit>
  <ax-custom-dropdown-edit
    *ngSwitchCase="ProposalCustomType.DROPDOWN"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-dropdown-edit>
  <ax-custom-chips-edit
    *ngSwitchCase="ProposalCustomType.CHIPS"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-chips-edit>
  <ax-custom-date-edit
    *ngSwitchCase="ProposalCustomType.DATE"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-date-edit>
  <ax-custom-rating-edit
    *ngSwitchCase="ProposalCustomType.RATING"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-rating-edit>
  <ax-custom-rich-text-edit
    *ngSwitchCase="ProposalCustomType.RICH_TEXT"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-rich-text-edit>
  <ax-custom-toggle-edit
    *ngSwitchCase="ProposalCustomType.TOGGLE"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="false"
    [readonly]="readonly"
  >
  </ax-custom-toggle-edit>
  <ax-custom-number-edit
    *ngSwitchCase="ProposalCustomType.NUMBER"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-number-edit>
  <ax-custom-url-edit
    *ngSwitchCase="ProposalCustomType.URL"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
    [enableValidation]="enableValidation"
  ></ax-custom-url-edit>
  <ax-custom-default-edit
    *ngSwitchDefault
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [requestedPropertyType]="requestedPropertyType"
    [form]="form"
    [name]="name"
    [autofocus]="autofocus"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-custom-default-edit>
</ng-container>
