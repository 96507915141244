import {HttpClient} from '@angular/common/http';
import {Component, Inject, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {BASE_URL} from '@aztrix/environment';
import {avatarUrl, displayName} from '@aztrix/helpers';
import {Profile} from '@aztrix/models';
import {ProposalLanguageRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'ax-proposal-header',
  templateUrl: './proposal-header.component.html',
  styleUrls: ['./proposal-header.component.scss'],
})
export class ProposalHeaderComponent implements OnChanges {
  @Input() proposal?: ProposalRepresentation | null;
  @Input() language?: ProposalLanguageRepresentation | null;
  displayName = displayName;
  avatarUrl = avatarUrl;

  useRouterLink = window.location.origin.includes(this.baseUrl);

  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);

  private _ownerId$ = this._proposal$.pipe(
    filter((proposal) => !!proposal?.id),
    map((proposal) => {
      return proposal.ownerId;
    }),
    distinctUntilChanged()
  );

  owner$ = this._ownerId$.pipe(
    filter((ownerId) => !!ownerId),
    mergeMap((ownerId) => {
      return this.getProfile(<string>ownerId);
    })
  );

  constructor(
    private _http: HttpClient,
    @Optional() @Inject(BASE_URL) public baseUrl: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.proposal && changes.proposal) {
      this._proposal$.next(this.proposal);
    }
  }

  getProfile(profileId: string): Observable<Profile> {
    return this._http.get<Profile>(`${this.baseUrl || ''}/r/profiles/${profileId}`);
  }
}
