import {Pipe, PipeTransform} from '@angular/core';
import {proposalType} from '@aztrix/helpers';
import {ProposalRepresentation} from '@aztrix/sdk';

@Pipe({
  name: 'proposalType',
})
export class ProposalTypePipe implements PipeTransform {
  transform(proposal: ProposalRepresentation) {
    return proposalType(proposal);
  }
}
