import {HttpClient} from '@angular/common/http';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AgreementRepresentation, MetadataRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, catchError, NEVER, ReplaySubject, shareReplay, switchMap} from 'rxjs';

@Component({
  templateUrl: './proposal-subscribe-modal.component.html',
  styleUrls: ['./proposal-subscribe-modal.component.scss'],
})
export class ProposalSubscribeModalComponent implements OnChanges {
  @Input() proposalId: string;
  @Input() agreement?: AgreementRepresentation;
  @Input() verification: boolean;
  @Input() languageCode?: string;
  @Input() subscriberMetadata: MetadataRepresentation[];

  error$ = new BehaviorSubject(false);
  proposalId$ = new ReplaySubject(1);

  proposal$ = this.proposalId$.pipe(
    switchMap((proposalId) => {
      return this._httpClient.get<ProposalRepresentation>(`/r/proposals/${proposalId}`).pipe(
        catchError(() => {
          this.error$.next(true);
          return NEVER;
        })
      );
    }),
    shareReplay(1)
  );

  constructor(private _httpClient: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.proposalId) {
      this.error$.next(true);
      console.error('No proposalId provided!');
    }

    if (changes.proposalId && this.proposalId) {
      this.proposalId$.next(this.proposalId);
    }
  }
}
