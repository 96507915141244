import {Pipe, PipeTransform} from '@angular/core';
import {displayValue, Subscribe} from '@aztrix/helpers';
import {
  AgreementRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'autocompleteProperties',
  standalone: true,
})
export class AutocompletePropertiesPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(
    proposalProperty: RequestedPropertyRepresentation,
    agreement?: AgreementRepresentation,
    myProfile?: ProfileRepresentation,
    autocompleteProperties?: PropertyRepresentation[]
  ): PropertyRepresentation[] {
    const properties = Subscribe.agreementProperties(proposalProperty, agreement, myProfile, true);
    if (autocompleteProperties) {
      const types = proposalProperty.requestedPropertyTypes?.map((rpt) => rpt.type);
      const result = [
        ...properties,
        ...autocompleteProperties.filter((p) => p.type && types?.includes(p.type)),
      ];
      return [...new Map(result.map((p) => [displayValue(this._translate, p), p])).values()];
    }
    return properties;
  }
}
