import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';
import {
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';
import {TranslatePipe} from '@aztrix/translate';

import {ProposalSubscribePropertyComponent} from '../../proposal-subscribe-property/proposal-subscribe-property.component';

@Component({
  selector: 'ax-proposal-subscribe-property-edit',
  templateUrl: './proposal-subscribe-property-edit.component.html',
  styleUrls: ['./proposal-subscribe-property-edit.component.scss'],
  standalone: true,
  imports: [ProposalSubscribePropertyComponent, ButtonModule, HelperPipesModule, TranslatePipe],
})
export class ProposalSubscribePropertyEditComponent {
  @Input() form: UntypedFormGroup;
  @Input() proposalId: string;
  @Input() property: RequestedPropertyRepresentation | undefined;
  @Input() autocompleteProperties: PropertyRepresentation[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() required = false;
  @Input() confirmed = false;
  @Input() isOwner = false;
  @Input() demo = false;
  @Input() showIcon = true;

  @Output() cancel = new EventEmitter<void>();
}
