import {CommonModule} from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {OverlayService} from '@aztrix/components/overlay';
import {ValueViewModule} from '@aztrix/components/value-view';
import {
  isRequiredProperty,
  ProposalPropertyTitlePipe,
  Subscribe,
  TemplateParser,
} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {
  AgreementRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  RequestedPropertyRepresentation,
} from '@aztrix/sdk';
import {LOCALE, TranslateService} from '@aztrix/translate';
import {BehaviorSubject, combineLatest, ReplaySubject, Subscription} from 'rxjs';
import {first, map, startWith, switchMap} from 'rxjs/operators';

import {ProposalSubscribePropertyEditComponent} from './proposal-subscribe-property-edit/proposal-subscribe-property-edit.component';

@Component({
  selector: 'ax-proposal-subscribe-text-template',
  templateUrl: './proposal-subscribe-text-template.component.html',
  styleUrls: ['./proposal-subscribe-text-template.component.scss'],
  standalone: true,
  imports: [CommonModule, ValueViewModule],
})
export class ProposalSubscribeTextTemplateComponent implements OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup | AbstractControl;
  @Input() templateForm?: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() autocompleteProperties: PropertyRepresentation[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() isOwner = false;
  @Input() demo = false;

  private _subscriptions: Subscription = new Subscription();

  private _form$ = new ReplaySubject<UntypedFormGroup>(1);
  private _templateForm$ = new ReplaySubject<UntypedFormGroup>(1);
  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);
  private _language$ = new ReplaySubject<ProposalLanguageRepresentation>(1);

  private _agreementProperties$ = this._form$
    .pipe(switchMap((form) => form.valueChanges.pipe(startWith(form.value))))
    .pipe(map((value) => Subscribe.formAgreementProperties(value)));

  value$ = combineLatest([
    this._templateForm$,
    this._proposal$,
    this._language$,
    this._locale$,
    this._agreementProperties$,
  ]).pipe(
    switchMap(([templateForm, proposal, language, locale, agreementProperties]) => {
      return TemplateParser.parse$(
        templateForm.value?.template,
        this._translate,
        proposal,
        language,
        locale,
        agreementProperties,
        true
      );
    })
  );

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    private _translate: TranslateService,
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this._form$.next(<UntypedFormGroup>this.form);
    }
    if (changes.templateForm && this.templateForm) {
      this._templateForm$.next(this.templateForm);
    }
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }
    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  clicked(event: Event) {
    if (!event?.target) {
      return undefined;
    }

    event.preventDefault();
    const button = this._getVariableButton(<Element>event.target);
    if (button && !button.getAttribute('disabled')) {
      const requestedPropertyId = button.getAttribute('data-index');
      if (requestedPropertyId) {
        const property = this.proposal?.requestedProperties?.find(
          (p) => p.requestedPropertyId === requestedPropertyId
        );

        ProposalPropertyTitlePipe.proposalPropertyTitle$(property, this.language, this._translate)
          .pipe(first())
          .subscribe((title) => {
            this._overlay.createModal(this._elementRef, ProposalSubscribePropertyEditComponent, {
              title,
              shadowDOM: false,
              init: (modal) => {
                modal.form = <UntypedFormGroup>(
                  this.form.get('requestedProperties')?.get(requestedPropertyId)
                );
                modal.property = property;
                const autocompleteProperties = Subscribe.agreementProperties(
                  <RequestedPropertyRepresentation>property,
                  this.agreement,
                  this.isOwner ? undefined : this.myProfile,
                  true
                );

                modal.autocompleteProperties = [];
                if (autocompleteProperties && autocompleteProperties.length) {
                  modal.autocompleteProperties = [
                    ...autocompleteProperties,
                    ...modal.autocompleteProperties,
                  ];
                }

                if (this.autocompleteProperties && this.autocompleteProperties.length) {
                  modal.autocompleteProperties = [
                    ...this.autocompleteProperties,
                    ...modal.autocompleteProperties,
                  ];
                }

                modal.language = this.language;
                modal.myProfile = this.myProfile;
                modal.required = this.isOwner
                  ? false
                  : isRequiredProperty(this.language, property?.requestedPropertyId);
                modal.confirmed = this.agreement?.agreementData?.confirmed || false;
                modal.isOwner = this.isOwner;
                modal.demo = this.demo;

                modal.cancel.pipe(first()).subscribe(() => {
                  this._overlay.closeModal();
                });
              },
            });
          });
      }
    }
  }

  private _getVariableButton(target: Element): Element | null {
    if (target.classList.contains('ax-quill-template-variable')) {
      return target;
    } else if (target.parentElement) {
      return this._getVariableButton(target.parentElement);
    } else {
      return null;
    }
  }
}
