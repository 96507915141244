import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActionFinishedComponent} from '@aztrix/components/action-finished';
import {OverlayService} from '@aztrix/components/overlay';
import {SendToEmailComponent} from '@aztrix/components/send-to-email';
import {
  CustomValidators,
  dummyPropertyForType,
  PropertyFormControl,
  typeFilter,
} from '@aztrix/helpers';
import {
  AgreementDataRepresentation,
  AgreementsService,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
} from '@aztrix/sdk';
import {TranslateService} from '@aztrix/translate';
import {filter, first, map, ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-send-to-email-button',
  templateUrl: './agreement-send-to-email-button.component.html',
  styleUrls: ['./agreement-send-to-email-button.component.scss'],
})
export class AgreementSendToEmailButtonComponent implements OnChanges {
  @Input() agreementData?: AgreementDataRepresentation;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreementCode?: string = '';
  @Input() color: 'primary' | 'transparent' = 'primary';

  private _agreementData$ = new ReplaySubject<AgreementDataRepresentation | undefined>(undefined);

  constructor(
    private _agreements: AgreementsService,
    private _overlay: OverlayService,
    private _translate: TranslateService,
    private _elementRef: ElementRef
  ) {}

  agreementProperties$ = this._agreementData$.pipe(
    filter((d) => !!d),
    map((d) => d?.agreementProperties)
  );
  properties$ = this.agreementProperties$.pipe(
    map((properties) => properties?.map((p) => p.property))
  );
  emailProperty$ = this.properties$.pipe(
    map((properties) => properties?.find(typeFilter(PropertyRepresentation.TypeEnum.EMAIL)))
  );

  openSendToEmailModal() {
    this.emailProperty$.subscribe((emailProperty) => {
      this._overlay.createModal(this._elementRef, SendToEmailComponent, {
        title: this._translate.instant('agreement.send-email.title'),
        init: (component) => {
          component.form = PropertyFormControl.create(
            emailProperty || dummyPropertyForType(PropertyRepresentation.TypeEnum.EMAIL),
            CustomValidators.propertyValid
          );
          component.hint = this._translate.instant('agreement.send-email.hint');
          component.sendEmail = () => {
            this._sendEmail(component.form.value.value);
          };
        },
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.agreementData && changes.agreementData) {
      this._agreementData$.next(this.agreementData);
    }
  }

  private _sendEmail(email: string | undefined): void {
    this._overlay.closeModal();
    this._agreements
      .sendManageAgreementMail(this.agreementCode || '', email, this.language?.languageCode || '')
      .pipe(first())
      .subscribe(() =>
        this._overlay.createModal(this._elementRef, ActionFinishedComponent, {
          title: 'agreement.send-email.success',
          init: (actionModal) => {
            actionModal.bodyIconClass = 'email';
            actionModal.bodyTitle = `agreement.send-email.success`;
            actionModal.bodyExplanation = 'agreement.send-email.explanation';
            actionModal.didClickButton.subscribe(() => this._overlay.closeModal());
          },
        })
      );
  }
}
