@if (form) {
  <ng-container [formGroup]="form">
    @if (canDisplay) {
      <div class="input-wrapper">
        <div class="type">
          <mat-form-field
            *ngIf="proposalProperty && (proposalProperty?.requestedPropertyTypes?.length || 0) > 1"
            class="c-input"
            [ngClass]="{
              'remove-subtext': propertyType,
              readonly: isReadonly && !required
            }"
            appearance="outline"
            style="width: 100%"
            [hideRequiredMarker]="!required"
          >
            <mat-label>
              {{ proposalProperty | proposalPropertyTitle: language }}
            </mat-label>
            <mat-select
              *ngIf="propertyTypeControl"
              [required]="required && !isOwner"
              [formControl]="propertyTypeControl"
            >
              <mat-select-trigger>
                {{
                  propertyTypeControl.value
                    | proposalPropertyTypeLabel
                      : (language
                          | proposalPropertyDescription
                            : proposalProperty.requestedPropertyId
                            : propertyTypeControl.value)
                }}
              </mat-select-trigger>
              <mat-option *ngIf="!required" [value]="null">
                {{ 'label.none' | translate }}
              </mat-option>
              <mat-option
                *ngFor="let requestedPropertyType of requestedPropertyTypes"
                [value]="requestedPropertyType.type"
              >
                {{
                  requestedPropertyType.type
                    | proposalPropertyTypeLabel
                      : (language
                          | proposalPropertyDescription
                            : proposalProperty.requestedPropertyId
                            : requestedPropertyType.type)
                }}
              </mat-option>
            </mat-select>

            <mat-error
              *ngFor="let error of propertyTypeControl?.errors | keyvalue"
              class="c-property-edit__error"
            >
              <ax-icon class="icon" name="alert-circle"></ax-icon>
              <span>{{ error.key | errorTranslationKey | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>

        @if (propertyType) {
          @for (c of propertyControlsList; track c) {
            <ax-proposal-property-edit
              *ngIf="c?.value?.type === propertyType"
              [form]="c"
              [name]="proposalProperty?.requestedPropertyId"
              [proposalId]="proposalId || ''"
              [requestedPropertyId]="proposalProperty?.requestedPropertyId || ''"
              [requestedPropertyType]="getRequestedPropertyType(proposalProperty, c?.value?.type)"
              [requestedPropertyTypeDescription]="
                language
                  | proposalPropertyDescription
                    : proposalProperty?.requestedPropertyId
                    : propertyType
              "
              [autocompleteProperties]="autocompleteProperties | propertiesOfType: propertyType"
              [required]="required"
              [demo]="demo"
              [autocomplete]="true"
              [showIcon]="showIcon"
              [autofocus]="autofocus"
              [readonly]="isOwner ? false : isReadonly"
              [enableValidation]="isOwner ? false : !isReadonly"
            ></ax-proposal-property-edit>
          }
        }
      </div>
    }
  </ng-container>
}
